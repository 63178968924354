import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/news.tsx";
export const pageTitle = "Armeria Newsletter vol. 0";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Mailchimp = makeShortcode("Mailchimp");
const ThankYou = makeShortcode("ThankYou");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "armeria-newsletter-vol-0",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#armeria-newsletter-vol-0",
        "aria-label": "armeria newsletter vol 0 permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Armeria Newsletter vol. 0`}</h1>
    <p {...{
      "className": "date"
    }}>{`14th May 2020`}</p>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-devs"
        }}>{`From the devs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#from-the-community"
        }}>{`From the community`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#we-need-your-comments"
        }}>{`We need your comments`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#good-first-issues"
        }}>{`Good first issues`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#stay-tuned"
        }}>{`Stay tuned`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#thank-you"
        }}>{`Thank you!`}</a></li>
    </ul>
    <video {...{
      "className": "hideOnReducedMotion",
      "src": "/bf04f0ae6df78db5375887fb6f8e2f78/armeria.m4v",
      "preload": "none",
      "autoPlay": true,
      "muted": true,
      "loop": true,
      "style": {
        "width": "282px",
        "height": "112px"
      }
    }}>{`
  `}<img parentName="video" {...{
        "src": "/2cbd433cd318026a1c3017f022dbc263/armeria.gif",
        "loading": "lazy",
        "width": 282,
        "height": 112
      }}></img>
    </video>
    <h2 {...{
      "id": "from-the-devs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-devs",
        "aria-label": "from the devs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the devs`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://armeria.dev/"
        }}>{`Our brand new web site`}</a>{` is now available! 🦄`}
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://armeria.dev/docs"
            }}>{`Official documentation`}</a>{` that looks better`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://armeria.dev/community/articles"
            }}>{`Fascinating articles and slides`}</a>{` from our community`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://armeria.dev/community"
            }}>{`Contributor manual`}</a>{` for your next participation`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/release-notes/0.99.5"
        }}>{`Armeria 0.99.5`}</a>{` has been released.`}
        <ul parentName="li">
          <li parentName="ul">{`New `}<a parentName="li" {...{
              "href": "type://RetryRule:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/retry/RetryRule.html"
            }}>{`type://RetryRule`}</a>{` API that allows convenient construction of complex retry policy`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://spring.io/guides/gs/service-registration-and-discovery/"
            }}>{`Eureka service discovery`}</a>{` support`}</li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/grpc/grpc-kotlin"
            }}><inlineCode parentName="a">{`grpc-kotlin`}</inlineCode></a>{` support`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "from-the-community",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#from-the-community",
        "aria-label": "from the community permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`From the community`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://engineering.linecorp.com/en/blog/monitoring-a-spring-boot-app-in-kubernetes-what-i-learned-from-devoxx-belgium-2019/"
        }}>{`Monitoring a Spring Boot app in Kubernetes – What I learned from Devoxx Belgium 2019`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/nirvanarsc"
            }}><strong parentName="a">{`@nirvanarsc`}</strong></a>{` shows how he ran his Armeria-based Spring Boot application using Docker and Kubernetes.`}</li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://speakerdeck.com/line_developers/lesson-learned-from-the-adoption-of-armeria-to-lines-authentication-system"
        }}>{`Lessons learned from the adoption of Armeria to LINE's authentication system`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://github.com/imasahiro"
            }}><strong parentName="a">{`@imasahiro`}</strong></a>{` tells about his successful migration from a home-grown HTTP/2 server based on Netty 4.1.0.Beta8 (!) to Armeria.`}</li>
        </ul>
      </li>
    </ul>
    <h2 {...{
      "id": "we-need-your-comments",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#we-need-your-comments",
        "aria-label": "we need your comments permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`We need your comments`}</h2>
    <p>{`Your opinion means a lot to us. Please let us know what you think about these proposals:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2148"
        }}>{`#2148`}</a>{` Provide a way to build an HTTP client request fluently`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2364"
        }}>{`#2364`}</a>{` Split `}<a parentName="li" {...{
          "href": "type://WebClient:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/client/WebClient.html"
        }}>{`type://WebClient`}</a>{` into blocking / async versions?`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2629"
        }}>{`#2629`}</a>{` Context-awareness of `}<inlineCode parentName="li">{`ServiceRequestContext.blockingTaskExecutor()`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "good-first-issues",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#good-first-issues",
        "aria-label": "good first issues permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Good first issues`}</h2>
    <p>{`Want to contribute but not sure where to start from? Try one of these:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1810"
        }}>{`#1810`}</a>{` `}<inlineCode parentName="li">{`String`}</inlineCode>{`-based headers/content sanitizer implementation`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/1934"
        }}>{`#1934`}</a>{` `}<inlineCode parentName="li">{`:examples:static-files:test`}</inlineCode>{` fails when a home directory has `}<inlineCode parentName="li">{`index.html`}</inlineCode>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/line/armeria/issues/2119"
        }}>{`#2119`}</a>{` Override new `}<inlineCode parentName="li">{`CompletableFuture`}</inlineCode>{` methods in Java 12 for `}<inlineCode parentName="li">{`RequestContextAwareCompletableFuture`}</inlineCode></li>
    </ul>
    <h2 {...{
      "id": "stay-tuned",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#stay-tuned",
        "aria-label": "stay tuned permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Stay tuned`}</h2>
    <p>{`Sign up for our newsletters to keep up-to-date with new releases and useful tips!`}</p>
    <div style={{
      "maxWidth": "320px",
      "marginTop": "0.5rem"
    }}>
  <Mailchimp mdxType="Mailchimp" />
    </div>
    <h2 {...{
      "id": "thank-you",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#thank-you",
        "aria-label": "thank you permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Thank you!`}</h2>
    <ThankYou message={() => <p>Since our last newsletter, we received awesome contributions from the following contributors:</p>} usernames={['andrewoma', 'anuraaga', 'Charliocat', 'eonezhang', 'gary-lo', 'ghkim3221', 'heowc', 'hexoul', 'hueneburg', 'ikhoon', 'imasahiro', 'jrhee17', 'KarboniteKream', 'max904-github', 'minwoox', 'mono0x', 'renaudb', 'shakuzen', 'syleeeee', 'tobias-', 'trustin']} mdxType="ThankYou" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      